import http from '@/utils/http';

export function AddNewGoal(params){
	return http.post(`/v1/goal`,params)
}
export function GetGoalList(){
	return http.get(`/v1/goal`)
}
export function DeleteGoal(params){
	return http.delete(`/v1/goal/${params}`)
}
export function GetGoalDetails(params){
	return http.get(`/v1/goal/${params}`)
}